import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Subscription } from 'rxjs/index';
import './GumSelector.css';
import { State } from './store/state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300 + theme.spacing(3) * 2
    },
    margin: {
      height: theme.spacing(3)
    },
    wrapper: {
      margin: 8,
      position: 'relative'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1
    },
    header: {
      marginBottom: 24
    },
    input: {},
    submit: {
      width: '100%',
      backgroundColor: '#e91e63',
      color: '#FFFFFF',
      padding: 12,
      marginTop: 24,
      fontFamily: 'bubblegum',
      fontSize: '1.3rem',
      borderRadius: 0,
      transition: 'backgroundColor 300ms ease-in-out',
      '&:hover': {
        backgroundColor: '#ec407a',
        color: '#FFFFFF'
      },
      '&:active': {
        backgroundColor: '#ec407a',
        color: '#FFFFFF'
      }
    },
    disabledSubmit: {
      opacity: 0.5,
      cursor: 'default !important',
      color: '#FFFFFF !important',
      backgroundColor: '#757575 !important',
      boxShadow: 'none'
    },
    paper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    pieces: {
      color: '#ec407a'
    },
    close: {
      position: 'absolute',
      top: 0,
      right: 0
    }
  })
);

export interface GumSelectorProps {
  complete(pay: boolean): void;
}

export default function GumSelector(props: GumSelectorProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState<number | string | Array<number | string>>(10);
  const [max, setMax] = React.useState(1000);
  const [loading, setLoading] = React.useState(false);
  const [pieces, setPieces] = React.useState(null);
  const [valid, setValid] = React.useState(true);
  const [action, setAction] = React.useState('Continue');
  const [helperText, setHelperText] = React.useState('');
  const {complete} = props;

  const stage = () => {
    if (!valid) {
      complete(false);
      return;
    }
    setLoading(true);
    setAction('Loading');
    State.stageRandom(parseInt(value as string, 10))
      .then(() => {
        setLoading(false);
        setValid(true);
        setAction('Continue');
        complete(true);
      });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const value: number = event.target.value === '' ? 1 : Number(event.target.value);
    setValue(event.target.value);
  };

  const handleBlur = () => {
    if (value < 1 || isNaN(value as any)) {
      setValue(1);
    } else if (value > max) {
      setValue(max);
    } else {
      setValue(parseInt(value as string, 10))
    }
  };

  React.useEffect(() => {
    if (pieces) {
      (pieces as any).focus();
    }

    const sub: Subscription = State.remainingGum$.subscribe((remaining: number) => {
      setMax(remaining);
    });
    return function cleanup() {
      sub.unsubscribe();
    };
  });

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.header}>Donate</Typography>
      <Paper style={{position: 'relative'}} >
        <IconButton size="medium" style={{position: 'absolute', zIndex: 8, right: 0, padding: 4}} onClick={() => complete(false)}>
          <CloseIcon fontSize="inherit"/>
        </IconButton>
        <TextField
          inputRef={el => setPieces(el)}
          error={!valid}
          helperText={helperText}
          id="outlined-number"
          className={classes.input}
          type="number"
          onBlur={handleBlur}
          onChange={handleInputChange}
          value={value}
          defaultValue={value}
          inputProps={{
            min: 0,
            max: max,
            step: 1
          }}
        />
        <Button
          onClick={stage}
          classes={{root: classes.submit, disabled: classes.disabledSubmit}}
          disabled={loading || !valid}
        >
          <span className={loading ? 'loading' : ''}>{action}</span>
        </Button>

      </Paper>
    </div>
  );
}