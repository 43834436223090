import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import { paypalSandboxClientId } from '../utils/paypal';

export interface PayPalFormProps {
  amount: number;
  onSuccess: Function;
  currency: string;
  processing: boolean;
  error: any
}

export default function PayPalForm(props: PayPalFormProps) {
  const [active, setActive] = React.useState<boolean>(false);
  const {amount, onSuccess, currency, processing, error} = props;

  return (
    <div>
      <button
        className={`SubmitButton SubmitButtonPayPal ${active ? 'SubmitButtonPayPal--active' : ''} ${error ? 'SubmitButton--error' : ''}`}
        disabled={processing}
        onClick={() => {
          setActive(!active);
        }}
      >
        {active ? 'Close' : 'Donate via PayPal'}
      </button>
      <Collapse in={active}>
        <PayPalButton
          amount={amount}
          currency={currency}
          onSuccess={onSuccess}
          options={{
            clientId: paypalSandboxClientId
          }}
          style={{color: 'blue'}}
        />
      </Collapse>
    </div>
  );
}
