import { PaymentMethodCreateParams } from '@stripe/stripe-js';
import { BehaviorSubject } from 'rxjs/index';
import { IGum } from '../models';
import { Backend } from './backend';

const backend: Backend = new Backend();

const staged$: BehaviorSubject<IGum[]> = new BehaviorSubject<IGum[]>([]);

export interface ElementHeights {
  header: number;
  footer: number;
}

export const State = {
  staged$: staged$,
  stagedToggle: (gum: IGum) => {
    const staged: IGum[] = staged$.value;
    const index: number = staged.findIndex((g: IGum) => g.id === gum.id);
    if (index > -1) {
      // It exists, remove it
      staged.splice(index, 1);
    } else {
      // It doesn't exist, add it
      staged.push(gum);
    }
    staged$.next(staged);
  },
  resetStaged: () => staged$.next([]),
  stageRandom: (amount: number) => {
    staged$.next([]);
    return backend.stageRandom(amount, staged$.value)
      .then((pieces: IGum[]) => {
        console.log('Staged:', pieces);
        staged$.next(pieces);
      });
  },
  availableGum$: backend.availableGum$,
  removedGum$: backend.removedGum$,
  raised$: backend.raised$,
  userGumPurchases$: backend.userGumPurchases$,
  remainingGum$: backend.remainingGum$,
  loading$: backend.loading$,
  phone$: backend.phone$,
  addPhone: backend.addPhone,
  finalizePurchase: (details: PaymentMethodCreateParams.BillingDetails) => backend.purchase(staged$.value, details)
    .then(() => {
      staged$.next([]);
    }),
  elementHeights$: new BehaviorSubject<ElementHeights>({header: 80, footer: 60})
};
