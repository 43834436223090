import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { Logo } from './Logo';
import ValueProps from './ValueProps';
import './Welcome.css';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    padding: 0
  },
  subtext: {
    fontSize: 10,
    marginTop: 16
  },
  submit: {
    width: '100%',
    backgroundColor: '#e91e63',
    color: '#FFFFFF',
    padding: 12,
    marginTop: 24,
    fontFamily: 'bubblegum',
    fontSize: '1.3rem',
    borderRadius: 0,
    textAlign: 'center',
    transition: 'backgroundColor 300ms ease-in-out',
    '&:hover': {
      backgroundColor: '#ec407a',
      color: '#FFFFFF'
    },
    '&:active': {
      backgroundColor: '#ec407a',
      color: '#FFFFFF'
    }
  },
  disabledSubmit: {
    opacity: 0.5,
    cursor: 'default',
    color: '#FFFFFF !important',
    backgroundColor: '#757575',
    boxShadow: 'none'
  },
  circularProgress: {
    width: '1.3rem',
    height: '1.3rem',
    color: '#FFFFFF'
  }
}));


export interface WelcomeProps {
  open: boolean
  loading: boolean;

  onClosed(): void;
}

export default function Welcome(props: WelcomeProps) {
  const classes = useStyles();
  const {open, loading} = props;

  const onSubmit = () => {
    if (!loading) {
      props.onClosed();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={props.onClosed}
        style={{minWidth: 375}}
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{textAlign: 'center'}}>
            <Logo/>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <ValueProps/>
        </DialogContent>
        <DialogActions style={{padding: 0}}>
          <Link
            href="https://big-table.com/seattle"
            rel="noreferrer"
            classes={{root: classes.submit}}
          >
            Big Table - Seattle
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );

}