import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';

const useStyles = makeStyles((theme) => ({
  network: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: 10,
    textAlign: 'center'
  },
  shareCount: {
    display: 'inline-flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    width: 0,
    marginTop: 3,
    fontSize: 12
  },
  shareButton: {
    cursor: 'pointer',
    '&:hover:not(:active)': {
      opacity: 0.75
    }
  },
  customIcon: {
    width: 32,
    height: 32
  }
}));


export interface ShareLinksProps {

}

export default function ShareLinks(props: ShareLinksProps) {
  const classes = useStyles();
  const shareUrl = 'http://gumwall.co';
  const title = 'GumWall';

  return (
    <div>
      <div className={classes.network}>
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className={classes.shareButton}
        >
          <FacebookIcon size={32} round/>
        </FacebookShareButton>

        <div>
          <FacebookShareCount url={shareUrl} className={classes.shareCount}>
            {count => count}
          </FacebookShareCount>
        </div>
      </div>

      <div className={classes.network}>
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className={classes.shareButton}
        >
          <FacebookMessengerIcon size={32} round/>
        </FacebookMessengerShareButton>
      </div>

      <div className={classes.network}>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className={classes.shareButton}
        >
          <TwitterIcon size={32} round/>
        </TwitterShareButton>

        <div className={classes.shareCount}>&nbsp;</div>
      </div>

      <div className={classes.network}>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className={classes.shareButton}
        >
          <WhatsappIcon size={32} round/>
        </WhatsappShareButton>

        <div className={classes.shareCount}>&nbsp;</div>
      </div>

      <div className={classes.network}>
        <LinkedinShareButton url={shareUrl} className={classes.shareButton}>
          <LinkedinIcon size={32} round/>
        </LinkedinShareButton>
      </div>

      <div className={classes.network}>
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className={classes.shareButton}
        >
          <RedditIcon size={32} round/>
        </RedditShareButton>

        <div>
          <RedditShareCount url={shareUrl} className={classes.shareCount}/>
        </div>
      </div>

      <div className={classes.network}>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className={classes.shareButton}
        >
          <EmailIcon size={32} round/>
        </EmailShareButton>
      </div>
    </div>
  );


}