import { createStyles, Theme, WithStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import FlipClock from 'x-react-flipclock';
import { Logo } from './Logo';
import { IGum } from './models';
import PaymentCounter from './PaymentCounter';


const styles = (theme: Theme) => createStyles({
  appBar: {
    padding: theme.spacing(2, 1),
    backgroundColor: '#2e2e2e',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    maxHeight: '120px'
  },
  header: {
    // overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center'
  },
  gumwall: {
    fontFamily: 'bubblegum',
    color: '#e91e63',
    marginRight: 24,
    lineHeight: '30px',
    height: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.0rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3.5rem'
    }
  },
  countdown: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.7)'
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(0.85)'
    },
    [theme.breakpoints.up('md')]: {
      transform: 'scale(0.9)'
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'scale(1)'
    }
  },
  countdownText: {
    fontSize: 10,
    color: '#e91e63',
    padding: theme.spacing(1, 1)
  }
});


export interface HeaderProps extends WithStyles<typeof styles> {
}

interface HeaderState {
  ready: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {

  private time: string = '2020-04-14 20:00:00';
  private timeDate: Date = new Date(this.time);
  private timerID: any | null = null;

  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      ready: false
    }
  }

  public componentDidMount(): void {
  }

  public componentWillUnmount(): void {
    clearInterval(this.timerID);
  }

  public render() {
    const {classes} = this.props;
    const {ready} = this.state;
    return (
      <AppBar id="gumwall-header" position="absolute" color="default" className={classes.appBar}>
        <Toolbar style={{width: '100%'}}>
          <div className={classes.header}>
            <Logo/>
            {/*{this.counter()}*/}
            <Hidden smDown>
              <Typography variant="h3" className={classes.gumwall} style={{color: '#2e2e2e'}}>GumWall</Typography>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  private countDown(classes) {
    return (
      <div className={classes.countdown}>
        <FlipClock
          type="countdown"
          count_to={this.time}
        />
        <Typography className={classes.countdownText}>
          {'Until donations begin'}
        </Typography>
      </div>
    )
  }

  private counter() {
    return (<PaymentCounter/>)
  }
}

export default withStyles(styles)(Header);
