export const CARD_OPTIONS = {
  iconStyle: 'solid' as 'solid',
  style: {
    base: {
      iconColor: '#f5f5f5',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#f5f5f5',
      },
    },
    invalid: {
      iconColor: '#f50057',
      color: '#f50057',
    },
  },
};