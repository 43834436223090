import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import icon from './g-icon.png';
import iconAlt from './g-icon-alt.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: '1.7rem'
    },
    [theme.breakpoints.up('sm')]: {
      height: '2.5rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '3.0rem'
    }
  }
}));

export function GumIcon(props: {alt: boolean}) {
  const classes = useStyles();
  return (<img src={props.alt ? iconAlt : icon} alt="Logo-Icon" className={classes.logo}/>);
}
