import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const valuePropStyles = makeStyles((theme) => ({
  numberBall: {
    color: '#FFFFFF',
    backgroundColor: '#e91e63',
    borderRadius: '50%',
    minWidth: 24,
    minHeight: 24,
    width: 24,
    height: 24,
    fontFamily: 'bubblegum'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center'
  },
  subitem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  }
}));
const valuePropsStyles = makeStyles((theme) => ({
  list: {
    fontSize: '14px',
    fontWeight: 500,
    listStyleType: 'none'
  },
  link: {
    cursor: 'pointer'
  }
}));


function ValueProp(props: { index: number, value: React.ReactNode }) {
  const {index, value} = props;
  const classes = valuePropStyles();
  return (
    <li>
      <Box className={classes.item} my={2}>
        <Box className={`${classes.numberBall} ${classes.subitem}`} mx={2}>{index + 1}</Box>
        <Box className={classes.subitem} style={{textAlign: 'left'}}>{value}</Box>
      </Box>
    </li>
  );
}

export default function ValueProps() {
  const classes = valuePropsStyles();
  const valueProps: React.ReactNode[] = [
    ('Donate to remove gum (1 gum = $1)'),
    ('When all gum has been removed, mission complete'),
    (
      <span>
        {'All proceeds go to '}
        <Link color="secondary" href="https://big-table.com/seattle" rel="noreferrer" target="_blank"
              className={classes.link}>Big Table - Seattle</Link>
      </span>
    )
  ];

  return (
    <Box p={1}>
      {/*<Typography variant="h2">Thank You!</Typography>*/}
      <Typography variant="subtitle1">We appreciate all of your support to make this happen!</Typography>
      <Typography variant="subtitle1">
        Sadly GumWall is now closed, please visit BigTable and offer your support.
      </Typography>
    </Box>
  );
}