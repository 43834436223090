import { createStyles, Theme, WithStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import { PaymentMethodCreateParams } from '@stripe/stripe-js';
import React from 'react';
import SimplifyNumber from 'simplify-number';
import { default as CheckoutForm } from './PaymentForm/CheckoutForm';
import { GumIcon } from './GumIcon';
import GumSelector from './GumSelector';
import { IGum } from './models';
import ShareLinks from './ShareLinks';
import { State } from './store/state';

const styles = (theme: Theme) => createStyles({
  root: {},
  counterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  counter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  action: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    border: '1px solid #FFFFFF',
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50
    },
    [theme.breakpoints.up('sm')]: {
      width: 90,
      height: 80
    },
    [theme.breakpoints.up('md')]: {
      width: 90,
      height: 80
    },
    [theme.breakpoints.up('lg')]: {
      width: 90,
      height: 80
    }
  },
  actionHeader: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.8rem'
    }
  },
  actionCaption: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    },
    fontSize: 10,
    marginBottom: 4
  },
  active: {
    backgroundColor: '#e91e63'
    // color: '#e91e63',
    // border: '1px solid #e91e63',
  },
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  submit: {
    width: '100%',
    backgroundColor: '#e91e63',
    color: '#FFFFFF',
    padding: 12,
    marginTop: 24,
    fontFamily: 'bubblegum',
    fontSize: '1.3rem',
    '&:hover': {
      backgroundColor: '#ec407a',
      color: '#FFFFFF'
    },
    '&:active': {
      backgroundColor: '#ec407a',
      color: '#FFFFFF'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
});


const SlideTransition = React.forwardRef(function SlideTransition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface PaymentCounterProps extends WithStyles<typeof styles> {
}

export interface PaymentCounterState {
  open: boolean;
  canPurchase: boolean;
  stagedCost: number;
  purchaseFinished: boolean;
  enterNumber: boolean;
  _staged: IGum[];
  _userGumPurchases: IGum[];
  _remaining: number;
  _raised: number;
}


/**
 * Scenario 1: When a user has staged a bunch of pieces of gum show how many it will cost and a button to purchase
 * Scenario 2: When a user has nothing staged, show how many have been purchased
 *
 * Note: When purchasing save purchase info
 *
 * Buttons:
 * 1. Reset: When clicked, clears staged items
 * 2. Purchased Count: (purchased + selected), if there are staged: when clicked, opens purchase screen
 * 3. Remaining: Number of remaining gum left, if clicked, opens drawer showing all recent purchases
 */
class PaymentCounter extends React.Component<PaymentCounterProps, PaymentCounterState> {

  constructor(props: PaymentCounterProps) {
    super(props);
    this.state = {
      open: false,
      canPurchase: false,
      stagedCost: 0,
      purchaseFinished: false,
      enterNumber: false,
      _staged: [],
      _userGumPurchases: [],
      _remaining: 10000,
      _raised: 0
    };
  }

  public componentDidMount(): void {
    State.staged$.subscribe((staged: IGum[]) => {
      const canPurchase = staged.length > 0;
      const stagedCost: number = staged.reduce((accumulator: number, piece: IGum) => {
        accumulator += piece.priceUSD;
        return accumulator;
      }, 0);
      this.setState({canPurchase: canPurchase, stagedCost: stagedCost, _staged: staged});
    });

    State.raised$.subscribe((raised: number) => {
      this.setState({_raised: raised});
    });

    State.remainingGum$.subscribe((remaining: number) => {
      this.setState({_remaining: remaining});
    });

    State.userGumPurchases$.subscribe((pieces: IGum[]) => {
      this.setState({_userGumPurchases: pieces});
    });
  }


  public render() {
    const {classes} = this.props;
    const {canPurchase, purchaseFinished, stagedCost, _staged, _userGumPurchases, _raised, enterNumber, _remaining, open} = this.state;
    return (
      <div className={classes.root}>
        <Grid container className={classes.counterContainer} spacing={2}>
          <Grid item>
            <Collapse in={canPurchase}>
              <ButtonBase className={`${classes.action} ${canPurchase ? classes.active : ''}`}
                          onClick={() => State.resetStaged()}>
                <Typography variant="body2" className={classes.actionCaption}>{''}</Typography>
                <Typography variant="h2" className={classes.actionHeader}>X</Typography>
                <Typography variant="body2" className={classes.actionCaption}>Clear</Typography>
              </ButtonBase>
            </Collapse>
          </Grid>
          <Grid item>
            <ButtonBase className={`${classes.action} ${canPurchase ? classes.active : ''}`}
                        onClick={() => this.setState({enterNumber: true})}>
              <Typography variant="body2" className={classes.actionCaption}>{''}</Typography>
              <Typography variant="h2" className={classes.actionHeader}><GumIcon alt={!canPurchase}/></Typography>
              <Typography variant="body2"
                          className={classes.actionCaption}>{canPurchase ? 'Restart' : 'Donate'}</Typography>
            </ButtonBase>
          </Grid>
          <Grid item>
            <ButtonBase className={`${classes.action} ${canPurchase ? classes.active : ''}`}
                        onClick={() => this.startPurchase()}>
              <Typography variant="body2" className={classes.actionCaption}>{''}</Typography>
              <Typography variant="h2"
                          className={classes.actionHeader}>{canPurchase ? `$${stagedCost}` : SimplifyNumber(_remaining)}</Typography>
              <Typography variant="body2"
                          className={classes.actionCaption}>{canPurchase ? 'Donate' : 'Goal'}</Typography>

            </ButtonBase>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          TransitionComponent={SlideTransition}
          keepMounted
          maxWidth={'md'}
          fullWidth={true}
          onClose={() => this.handleClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          style={{padding: 0}}
        >
          <DialogTitle
            id="alert-dialog-slide-title">{purchaseFinished ? 'A Community That Sticks Together Stays Together' : 'Thank You For Your Support'}</DialogTitle>
          <DialogContent style={{padding: 0}}>
            {purchaseFinished ? this.shareSocialMedia(classes, _remaining) : this.cardForm(_staged, stagedCost)}
          </DialogContent>
        </Dialog>
        <Backdrop className={classes.backdrop} open={enterNumber} style={{background: 'rgba(0, 0, 0, 0.75)'}}>
          <GumSelector complete={(pay: boolean) => this.onGumSelected(pay)}/>
        </Backdrop>
      </div>
    );
  }

  private cardForm(_staged, stagedCost) {
    return (
      <CheckoutForm pieces={_staged} priceUSD={stagedCost} successfulPurchase={this.successfulPurchase.bind(this)}/>
    );
  }

  private shareSocialMedia(classes, remaining) {
    return (
      <div className={classes.links}>
        <Typography variant="body2" style={{marginBottom: 24, paddingRight: 8, paddingLeft: 8}}>
          {`Only ${remaining} pieces remaining, please help spread the word!`}
        </Typography>
        <ShareLinks/>
        <Button className={classes.submit} onClick={() => this.handleClose()}>
          Finish
        </Button>
      </div>
    );
  }

  private handleClose() {
    this.setState({open: false, purchaseFinished: false});
  }

  private startPurchase() {
    const {_staged} = this.state;
    if (_staged.length > 0) {
      this.setState({open: true});
    }
  }

  private successfulPurchase(details: PaymentMethodCreateParams.BillingDetails) {
    console.log('Finalizing Purchase');
    State.finalizePurchase(details)
      .then(() => {
        console.log('Purchased');
        this.setState({purchaseFinished: true});
      })
      .catch((err: Error) => {
        console.error(err);
        this.setState({purchaseFinished: false});
      });
  }

  private onGumSelected(pay: boolean): void {
    console.log('GUM SELECTED', pay);
    this.setState({enterNumber: false, open: pay})
  }

}

export default withStyles(styles)(PaymentCounter);

