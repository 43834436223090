import { PaymentMethodCreateParams } from '@stripe/stripe-js';
import * as firebase from 'firebase';
import { BehaviorSubject } from 'rxjs/index';
import { filter, map } from 'rxjs/internal/operators';
import { IGum, IUser } from '../models';
import { CLAMP } from '../utils/math';

const config = {
  apiKey: 'AIzaSyBhdxjZCeQn9Y49H2jVXmoIV84qUNBaafE',
  authDomain: 'jumpstart-4dc62.firebaseapp.com',
  databaseURL: 'https://jumpstart-4dc62.firebaseio.com',
  projectId: 'jumpstart',
  storageBucket: 'jumpstart.appspot.com',
  messagingSenderId: '468928907434',
  appId: '1:468928907434:web:cdd8ad1bf01b6653',
  measurementId: 'G-3Z0P4BQ925'
};

const defaultProject = firebase.initializeApp(config);
const db: firebase.firestore.Firestore = defaultProject.firestore();
const auth: firebase.auth.Auth = defaultProject.auth();
const functions: firebase.functions.Functions = firebase.functions();
const analytics = firebase.analytics();


interface GumData {
  purchased: IGum[];
  available: IGum[];
}

export class Backend {
  public static createPaymentIntent: firebase.functions.HttpsCallable = functions.httpsCallable('stripe-createPaymentIntent');
  public static productDetails: firebase.functions.HttpsCallable = functions.httpsCallable('stripe-productDetails');

  public remainingGum$: BehaviorSubject<number> = new BehaviorSubject<number>(10000);
  public availableGum$: BehaviorSubject<IGum[]> = new BehaviorSubject<IGum[]>([]);
  public removedGum$: BehaviorSubject<IGum[]> = new BehaviorSubject<IGum[]>([]);
  public userGumPurchases$: BehaviorSubject<IGum[]> = new BehaviorSubject<IGum[]>([]);
  public raised$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public phone$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(localStorage.getItem('gumwall-phonenumber'));
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private gumwallUserSubscriber: null | Function = null;
  private gumwallSubscriber: null | Function = null;
  private gumwallRemainingSubscriber: null | Function = null;
  private gumLimit: number = 1000;

  constructor() {
    if (this.phone$.value) {
      this.phone$.next(this.phone$.value.replace(/\D/g, ''));
    }
    // @TODO sign in annomous - disable Scout9 listener
    auth.signInWithEmailAndPassword('test2@scout9.com', 'test1234')
      .then(() => {
        // 1. Listen to user changes
        this.phone$
          .pipe(
            filter((phone: null | string) => !!phone),
            map((phone: null | string) => {
              if (phone) {
                return phone.replace(/\D/g, '');
              } else {
                return null;
              }
            })
          )
          .subscribe((phone: string | null) => {
            if (phone) {
              if (this.gumwallUserSubscriber) {
                this.gumwallUserSubscriber();
              }
              this.gumwallUserSubscriber = db
                .collection('gumwall-gum-pieces')
                .where('owner', '==', phone)
                .onSnapshot((query: firebase.firestore.QuerySnapshot) => {
                  return query.docs.map((snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as IGum);
                });
            }
          });

        // 2. Listen to wall changes
        // @TODO test if new ones come in!
        //
        // this.gumwallSubscriber = db
        //   .collection('gumwall-gum-pieces')
        //   .where('owner', '==', null)
        //   .limit(this.gumLimit)
        //   .onSnapshot((query: firebase.firestore.QuerySnapshot) => {
        //     const data: GumData = this.buildGumData(
        //       query.docs
        //         .map((snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as IGum)
        //     );
        //     this.availableGum$.next(data.available);
        //     this.removedGum$.next(data.purchased);
        //     this.loading$.next(false);
        //     // this.remainingGum$.next(data.available.length);
        //   });

        // this.gumwallRemainingSubscriber = db
        //   .doc('gumwall-meta/remaining')
        //   .onSnapshot((doc: firebase.firestore.DocumentSnapshot) => {
        //     if (doc.exists) {
        //       const data: {
        //         available: number;
        //         purchased: number;
        //       } = doc.data() as any;
        //       this.raised$.next(data.purchased);
        //       this.remainingGum$.next(data.available);
        //     }
        //   });
      });
  }

  public unsubscribe(): void {
    if (this.gumwallUserSubscriber) {
      this.gumwallUserSubscriber();
    }
    if (this.gumwallSubscriber) {
      this.gumwallSubscriber();
    }
    if (this.gumwallRemainingSubscriber) {
      this.gumwallRemainingSubscriber();
    }
  }

  public async stageRandom(amount: number, currentStaged: IGum[]): Promise<IGum[]> {
    // limit staged + amount
    return db.collection('gumwall-gum-pieces')
      .where('owner', '==', null)
      .limit(amount + currentStaged.length)
      .get()
      .then((query: firebase.firestore.QuerySnapshot) => {
        return query.docs
          .map((doc: firebase.firestore.DocumentSnapshot) => doc.data() as IGum)
          .filter((gum: IGum) => {
            const index: number = currentStaged.findIndex((g: IGum) => gum.id === g.id);
            return index === -1;
          });
      });
  }

  public async addPhone(phone: string): Promise<void> {
    phone = phone.replace(/\D/g, '');
    localStorage.setItem('gumwall-phonenumber', phone);
    this.phone$.next(phone);
    const user: IUser = {
      uid: phone,
      phone: phone,
      email: null,
      firstName: null,
      lastName: null,
      gums: []
    };
    await db.collection('gumwall-users').doc(phone).set(user);
  }


  public async purchase(gums: IGum[], details: PaymentMethodCreateParams.BillingDetails): Promise<void> {
    // @TODO remove phone$
    let uid: string | undefined;
    const phoneDirty: undefined | string = details.phone;
    if (phoneDirty) {
      const phone: string = phoneDirty.replace(/\D/g, ''); // only grab numbers
      localStorage.setItem('gumwall-phonenumber', phone);
      this.phone$.next(phone);
      uid = phone;
    } else {
      console.warn('No phone found, using email instead (likely paying from paypal)');
      uid = details.email;
    }

    if (!uid) {
      throw new Error(`Could not resolve a UID from ${JSON.stringify(details)}`)
    }
    return db.runTransaction(async (tx: firebase.firestore.Transaction) => {
      // 1. Get user
      const userRef: firebase.firestore.DocumentReference = db.doc(`gumwall-users/${uid}`);
      const userDoc: firebase.firestore.DocumentSnapshot = await tx.get(userRef);
      const user: IUser = userDoc.exists ? userDoc.data() as IUser : {
        uid: uid as string,
        phone: null,
        email: null,
        firstName: null,
        lastName: null,
        gums: []
      };
      if (!user.gums) {
        user.gums = [];
      }
      user.firstName = details.name ? details.name : null;
      user.email = details.email ? details.email : null;
      user.phone = details.phone ? details.phone : null;
      user.gums.push(...gums.map((gum: IGum) => gum.id));

      // 2. Save user
      tx.set(userRef, user, {merge: true});

      // 3 Save Gums
      gums.forEach((gum: IGum) => {
        gum.owner = user.uid;
        gum.purchased = new Date().toISOString();
        tx.set(db.doc(`gumwall-gum-pieces/${gum.id}`), gum, {merge: true});
      });
    });
  }

  private buildGumData(pieces: IGum[]): GumData {
    const data: GumData = {
      purchased: [],
      available: []
    };
    pieces.forEach((piece: IGum) => {
      if (piece.owner) {
        data.purchased.push(piece);
      } else {
        data.available.push(piece);
      }
    });

    return data;
  }

  private alterGum(gum: IGum, headerHeight: number, footerHeight: number): IGum {
    const clamps: number[] = [20, 80];
    const y: number = CLAMP(parseInt(gum.y as string, 10), 0, 100);
    if (y < clamps[0]) {
      gum.y = `calc(${y}vh + ${headerHeight}px)`;
    } else if (y > clamps[1]) {
      gum.y = `calc(${y}vh - ${footerHeight}px)`;
    } else {
      gum.y = `${y}vh`;
    }
    return gum;
  }

}

