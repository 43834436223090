import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmailOutline from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 10,
    color: '#FFF',
    minWidth: 'calc(100vw - 48px)' // @TODO this might break styling
  },
  link: {
    cursor: 'pointer',
    color: '#e91e63'
  }
}));

export default function Copyright() {
  const classes = useStyles();
  return (
    <Typography variant="body2" color="secondary" className={classes.root}>
      {'All proceeds will go to '}
      <Link
        href="https://big-table.com/seattle"
        rel="noreferrer"
        target="_blank"
        className={classes.link}
      >
        Big Table - Seattle
      </Link>
      {', supporting hospitality workers and their families during the COVID-19 crisis.'}
      <br/>
      <Link
        target="_top"
         rel="noopener noreferrer"
         href="mailto:patrickopie@scout9.com?Subject=GumWall"
         style={{fontSize: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}
        className={classes.link}
      >
        <IconButton style={{color: '#e91e63', fontSize: 10, padding: 4}}>
          <EmailOutline fontSize="inherit" /> {/* icon */}
        </IconButton>
        Contact
      </Link>
    </Typography>
  );
}