import React from 'react';
import { Rect } from 'react-konva';
import { animated, Spring } from 'react-spring/renderprops-konva';
import { IGum } from './models';
import { State } from './store/state';
import { RAND_INT } from './utils/math';

export interface GumPieceProps {
  gum: IGum;
  staged: boolean;
  index: number;
  x: number;
  y: number;
  pointerDown: boolean;
}

interface GumPieceState {
  hovering: boolean;
  pressing: boolean;
}


export class GumPiece extends React.Component<GumPieceProps, GumPieceState> {

  private rect;

  constructor(public props: GumPieceProps) {
    super(props);
    this.state = {
      hovering: false,
      pressing: false
    };
  }

  public render() {
    const {gum, staged, x, y} = this.props;
    const {pressing, hovering} = this.state;
    return (
      <Spring
        native
        from={{
          fill: gum.color,
          scaleX: 1,
          scaleY: 1,
          rotate: 0
        }}
        to={{
          fill: staged ? '#FFFFFF' : gum.color,
          scaleX: pressing ? 0.8 : (hovering ? 1.2 : 1),
          scaleY: pressing ? 0.8 : (hovering ? 1.2 : 1),
          rotate: (pressing || hovering) ? RAND_INT(0, 7) : 0
        }}
      >
        {props => (
          <animated.Rect
            {...props}
            x={x}
            y={y}
            width={gum.width}
            height={gum.height}
            offset={{
              x: gum.width / 2,
              y: gum.height /2
            }}
            cornerRadius={5}
            onMouseEnter={this.onHover.bind(this)}
            onMouseOut={this.onHoverRelease.bind(this)}
            onMouseDown={this.onPress.bind(this)}
            onMouseUp={this.onPressRelease.bind(this)}
            onTouchEnd={this.onPressRelease.bind(this)}
            onTouchStart={this.onPress.bind(this)}
            onClick={() => State.stagedToggle(this.props.gum)}
          />
        )}
      </Spring>
    );
  }

  private onPress(event): void {
    this.setState({pressing: true});
  }

  private onPressRelease(event): void {
    this.setState({pressing: false});
  }

  private onHover(event): void {
    const {pointerDown} = this.props;
    this.setState({hovering: true});
    console.log("Hovering, Pointer:", pointerDown);
    if (pointerDown) {
      State.stagedToggle(this.props.gum);
    }
  }

  private onHoverRelease(event): void {
    this.setState({hovering: false});
  }

  private changeSize(action?: 'hover' | 'click') {
    const {gum, staged} = this.props;
    let scale: number = 1;
    if (action === 'hover') {
      scale = 1.2;
    } else if (action === 'click') {
      scale = 0.8;
    }
    this.rect.to({
      color: staged ? '#FFFFFF' : gum.color,
      scaleX: scale,
      scaleY: scale,
      duration: 0.2,
      rotate: action ? RAND_INT(-5, 5) : 0
    });
  };

}