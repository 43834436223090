import { createStyles, Theme, WithStyles } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { Layer, Stage } from 'react-konva';
import Alert from './Alert';
import Copyright from './Copyright';
import { GumPiece } from './GumPiece';
import Header from './Header';
import { IGum } from './models';
import { State } from './store/state';
import { stripePromise } from './utils/stripe';
import Welcome from './Welcome';


let mainTheme = createMuiTheme({
  typography: {
    // fontFamily: 'BubbleGum'
  },
  palette: {
    secondary: {
      main: pink[500]
    }
  }
});
mainTheme = responsiveFontSizes(mainTheme);


const styles = ({palette, spacing}: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    zIndex: 3,
    width: '100vw',
    height: '100vh',
    backdropFilter: 'blur(3px)'
  },
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center'
  },
  footer: {
    padding: spacing(2, 1),
    marginTop: 'auto',
    backgroundColor: '#2e2e2e',
    color: '#e91e63',
    // backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
    textAlign: 'center',
    maxWidth: '100%'
  }
});


const container = {
  // hidden: {opacity: 0},
  visible: {
    opacity: 1
    // transition: {
    //   delay: 0.3,
    //   when: 'beforeChildren',
    //   staggerChildren: 0.1
    // }
  }
};


interface AppProps extends WithStyles<typeof styles> {

}

interface AppState {
  snack: boolean;
  ready: boolean;
  welcome: boolean;
  open: boolean;
  loading: boolean;
  canvasWidth: number;
  canvasHeight: number;
  pointerDown: boolean;
  _available: IGum[];
  _staged: IGum[];

}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = {
      snack: false,
      ready: false,
      welcome: true,
      open: false,
      loading: false,
      canvasWidth: window.innerWidth,
      canvasHeight: window.innerHeight,
      pointerDown: false,
      _available: [],
      _staged: [],
    };
  }

  public componentDidMount(): void {
    // const header: HTMLElement | null = document.getElementById('gumwall-header');
    // const footer: HTMLElement | null = document.getElementById('gumwall-footer');
    // if (header && footer) {
    //   State.elementHeights$.next({
    //     header: header.offsetHeight,
    //     footer: footer.offsetHeight
    //   });
    // }
    document.body.classList.remove('body-loaded');
    State.availableGum$.subscribe((availableGum: IGum[]) => {
      this.setState({_available: availableGum});
    });
    State.loading$.subscribe((loading: boolean) => {
      setTimeout(() => {
        document.body.classList.add('body-loaded');
        this.setState({ready: !loading});
      }, 1000);
    });
    State.staged$.subscribe((staged: IGum[]) => {
      this.setState({_staged: staged});
    });
    this.setState({
      canvasWidth: window.innerWidth,
      canvasHeight: window.innerHeight
    })
  }

  public render() {
    const {snack, ready, welcome, canvasWidth, canvasHeight, pointerDown, _available, _staged} = this.state;
    const {classes} = this.props;
    return (
      <Elements stripe={stripePromise} options={{}}>
        <ThemeProvider theme={mainTheme}>
          <div className={classes.root}>
            <Snackbar
              autoHideDuration={2000}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              key={`top,center`}
              open={snack}
              onClose={() => this.setState({snack: false})}
            >
              <Alert onClose={() => this.setState({snack: false})} severity="success">
                Gum has been removed!
              </Alert>
            </Snackbar>
            {ready ? <div/> : <div className={classes.overlay} onDoubleClick={() => this.setState({ready: true})}/>}
            <Header/>
            <Welcome open={welcome} onClosed={this.welcomeClosed.bind(this)} loading={!ready}/>
            <Stage width={canvasWidth} height={canvasHeight}>
              <Layer
                onMouseDown={() => this.setState({pointerDown: true})}
                onMouseUp={() => this.setState({pointerDown: false})}
                onTouchEnd={() => this.setState({pointerDown: false})}
                onTouchStart={() => this.setState({pointerDown: true})}
              >
                {_available.map((gum: IGum, index: number) => {
                  // @TODO test performance
                  const isStaged: boolean = _staged.findIndex((piece: IGum) => piece.id === gum.id) > -1;
                  const {x, y} = this.position(gum, canvasWidth, canvasHeight);
                  return (
                    <GumPiece
                      key={gum.id}
                      index={index}
                      gum={gum}
                      staged={isStaged}
                      x={x}
                      y={y}
                      pointerDown={pointerDown}
                    />
                  );
                })}
              </Layer>
            </Stage>
            {/*<motion.ul*/}
            {/*className="container"*/}
            {/*variants={container}*/}
            {/*initial="hidden"*/}
            {/*animate="visible"*/}
            {/*>*/}
            {/*{_available.map((gum: IGum, index: number) =>*/}
            {/*<Gum*/}
            {/*key={gum.id}*/}
            {/*index={index}*/}
            {/*gum={gum}*/}
            {/*/>*/}
            {/*)}*/}
            {/*</motion.ul>*/}
            <footer id="gumwall-footer" className={classes.footer}>
              <Container>
                <Copyright/>
              </Container>
            </footer>
          </div>
        </ThemeProvider>
      </Elements>
    );
  }

  private welcomeClosed(): void {
    this.setState({welcome: false});
  };

  private position(gum: IGum, canvasWidth: number, canvasHeight: number): {x: number, y: number} {
    const vw: number = (typeof gum.x === 'string' ? parseInt(gum.x, 10) : gum.x)/100;
    const vy: number = (typeof gum.y === 'string' ? parseInt(gum.y, 10) : gum.y)/100;
    return {
      x: vw * canvasWidth,
      y: vy * canvasHeight
    }
  }

}


export default withStyles(styles)(App);
