import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from './gumwall.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: '1.7rem'
    },
    [theme.breakpoints.up('sm')]: {
      height: '2.5rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '3.0rem'
    },
    [theme.breakpoints.up('lg')]: {
      height: '3.5rem'
    }
  }
}));

export function Logo() {
  const classes = useStyles();
  return (<img src={logo} alt="Logo" className={classes.logo}/>);
}